import { useEffect, useState } from 'react';
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import useAuth from 'src/hooks/useAuth';
import Avatar from 'src/componentes/avatar';
import ContentManager from 'src/componentes/content-manager';
import TermosUso from 'src/paginas/TermosUso';
import Sobre from './About';
import { capitalizeFirstLetter } from 'src/utils/string';
import { MODULOS } from 'src/utils/constants';

const manuals = {
  'pt-br': {
    [MODULOS.GESTAO_ESTRATEGICA]:
      'https://www.manula.com/manuals/stratec-informatica/gestao-estrategica-2-0/1/pt/topic/manual-de-treinamento-de-usuario',
    [MODULOS.GESTAO_RISCO]:
      'https://www.manula.com/manuals/stratec-informatica/belt/1/pt/topic',
    [MODULOS.AVALIACAO_DESEMPENHO]:
      'https://www.manula.com/manuals/stratec-informatica/lift/1/pt/topic',
    [MODULOS.MATRIZ_ORCAMENTARIA]:
      'https://www.manula.com/manuals/stratec-informatica/grid/1/pt/topic',
    [MODULOS.REMUNERACAO_VARIAVEL]:
      'https://www.manula.com/manuals/stratec-informatica/score/1/pt/topic',
  },
  es: {
    [MODULOS.GESTAO_ESTRATEGICA]:
      'https://www.manula.com/manuals/stratec-informatica/gestao-estrategica-2-0/1/es/topic/user-training-manual',
    [MODULOS.GESTAO_RISCO]:
      'https://www.manula.com/manuals/stratec-informatica/belt/1/es/topic',
    [MODULOS.AVALIACAO_DESEMPENHO]:
      'https://www.manula.com/manuals/stratec-informatica/lift/1/es/topic',
    [MODULOS.MATRIZ_ORCAMENTARIA]:
      'https://www.manula.com/manuals/stratec-informatica/grid/1/es/topic',
    [MODULOS.REMUNERACAO_VARIAVEL]:
      'https://www.manula.com/manuals/stratec-informatica/score/1/es/topic',
  },
  default: {
    [MODULOS.GESTAO_ESTRATEGICA]:
      'https://www.manula.com/manuals/stratec-informatica/gestao-estrategica-2-0/1/en/topic/user-training-manual',
    [MODULOS.GESTAO_RISCO]:
      'https://www.manula.com/manuals/stratec-informatica/belt/1/en/topic',
    [MODULOS.AVALIACAO_DESEMPENHO]:
      'https://www.manula.com/manuals/stratec-informatica/lift/1/en/topic',
    [MODULOS.MATRIZ_ORCAMENTARIA]:
      'https://www.manula.com/manuals/stratec-informatica/grid/1/en/topic',
    [MODULOS.REMUNERACAO_VARIAVEL]:
      'https://www.manula.com/manuals/stratec-informatica/score/1/en/topic',
  },
};

function NavbarUser() {
  const history = useHistory();
  const intl = useIntl();
  const [currentManualLink, setCurrentManualLink] = useState('');

  const { user, logout, module } = useAuth();

  const renderAvatar = () => (
    <Avatar
      foto={user?.fotoArquivo}
      size={35}
      showLabel={false}
      label={user?.nome}
    />
  );

  const handleTerms = () => {
    ContentManager.addContent(<TermosUso history={history} />);
  };

  const handleAbout = () => {
    ContentManager.addContent(<Sobre />);
  };

  useEffect(() => {
    const localeManual = manuals[intl.locale] || manuals['default'];
    const current =
      localeManual[module?.id] || localeManual[MODULOS.GESTAO_ESTRATEGICA];
    setCurrentManualLink(current);
  }, [module]);

  return (
    <UncontrolledDropdown nav inNavbar>
      <span className="d-inline-block d-sm-none">
        <DropdownToggle nav caret>
          {renderAvatar()}
        </DropdownToggle>
      </span>
      <span className="d-none d-sm-inline-block">
        <DropdownToggle className="d-flex align-items-center" nav>
          <div className="mr-2 text-sm text-right text-dark">
            <div>{intl.formatMessage({ id: 'bemVindo' })}!</div>
            <strong>{user?.nome}</strong>
          </div>
          {renderAvatar()}
        </DropdownToggle>
      </span>
      <DropdownMenu right>
        <DropdownItem
          onClick={() => history.push('/minhas-informacoes/meus-dados')}
        >
          {intl.formatMessage({ id: 'label.meusDados' })}
        </DropdownItem>
        <DropdownItem divider />
        <a
          style={{ color: 'inherit', textDecoration: 'none' }}
          target="_blank"
          rel="noopener noreferrer"
          href={currentManualLink}
        >
          <DropdownItem>
            {intl.formatMessage({ id: 'manualUsuario' })}
          </DropdownItem>
        </a>
        {
          <DropdownItem onClick={() => history.push('/notas-versao')}>
            {intl.formatMessage({ id: 'notasVersao' })}
          </DropdownItem>
        }
        <DropdownItem onClick={handleAbout}>
          {intl.formatMessage({ id: 'sobre' })}
        </DropdownItem>
        <DropdownItem onClick={handleTerms}>
          {capitalizeFirstLetter(intl.formatMessage({ id: 'verTermosUso' }))}
        </DropdownItem>
        <DropdownItem onClick={logout}>
          {intl.formatMessage({ id: 'label.sair' })}
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}

export default NavbarUser;
