import React, { Suspense, Fragment, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import GuestGuard from 'src/routes/GuestRoute';
import AuthGuard from 'src/routes/UserRoute';
import AddonGuard from 'src/routes/AddonRoute';
import LoadingScreen from 'src/componentes/LoadingScreen';
import DashboardLayout from 'src/layout/DashboardLayout';
import { ADDONS } from 'src/utils/constants';

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route) => {
        const Component = route.component;
        const Guard = route.guard || Fragment;
        const LayoutComponent = route.layout || Fragment;

        return (
          <Route
            key={route.path}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard parameters={route.parameters}>
                <LayoutComponent>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </LayoutComponent>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/paginas/Errors/NotFoundView')),
  },
  {
    exact: true,
    path: '/login',
    guard: GuestGuard,
    component: lazy(() => import('src/paginas/login')),
  },
  {
    exact: true,
    path: '/logout',
    guard: GuestGuard,
    component: lazy(() => import('src/paginas/logout/LogoutView')),
  },
  {
    exact: true,
    path: '/verifyAdfs',
    guard: GuestGuard,
    component: lazy(() => import('src/paginas/login/VerifyAdfs')),
  },
  {
    exact: true,
    path: '/recuperar-senha',
    guard: GuestGuard,
    component: lazy(() => import('src/paginas/recuperar-senha')),
  },
  {
    exact: true,
    path: '/definir-senha',
    guard: GuestGuard,
    component: lazy(() => import('src/paginas/definir-senha')),
  },
  {
    exact: true,
    path: '/licenca',
    guard: GuestGuard,
    component: lazy(() => import('src/paginas/licenca')),
  },
  {
    exact: true,
    path: '/consulta-externa',
    guard: GuestGuard,
    component: lazy(() => import('src/paginas/consulta-externa/view-page')),
  },
  {
    exact: true,
    path: '/Home/erroADFS',
    guard: GuestGuard,
    component: lazy(() => import('src/paginas/login/erro-adfs')),
  },
  {
    exact: true,
    path: '/relatorio-auth',
    guard: GuestGuard,
    component: lazy(() => import('src/paginas/relatorio')),
  },
  {
    exact: true,
    path: '/datas',
    guard: GuestGuard,
    component: lazy(() => import('src/utils/datas')),
  },
  {
    exact: true,
    path: '/loginUnificado',
    guard: GuestGuard,
    component: lazy(() => import('src/paginas/login/loginUnificado')),
  },
  {
    exact: true,
    path: '/autenticacao-externa',
    guard: GuestGuard,
    component: lazy(() => import('src/paginas/login/autenticacaoExterna')),
  },
  {
    path: '/',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app-logout-adfs',
        component: lazy(() => import('src/paginas/logout/app-logout-adfs')),
      },
      {
        exact: true,
        path: '/redirect-to-app',
        component: lazy(() => import('src/paginas/logout/redirect-to-app')),
      },
      {
        exact: true,
        path: '/relatorio-apresentacao',
        component: lazy(() =>
          import('src/paginas/relatorio/relatorio-apresentacao')
        ),
      },
      {
        exact: true,
        path: '/relatorio-periodico',
        component: lazy(() =>
          import('src/paginas/relatorio/relatorio-periodico')
        ),
      },
      {
        exact: true,
        path: '/relatorio-acompanhamento',
        component: lazy(() =>
          import('src/paginas/relatorio/relatorio-acompanhamento')
        ),
      },
      {
        exact: true,
        path: '/calendario-exportacao',
        component: lazy(() =>
          import('src/paginas/AgendaColaborador/CalendarioExportacao')
        ),
      },
      {
        exact: true,
        path: '/',
        component: lazy(() => import('src/paginas/inicio')),
      },
      {
        exact: true,
        path: '/confirmar-reuniao',
        component: lazy(() => import('src/paginas/reuniao/confirmar-reuniao')),
      },
      {
        exact: true,
        path: '/confirmar-reuniao-gente',
        component: lazy(() =>
          import(
            'src/paginas/avaliacao-desempenho/CicloAvaliacao/CicloAvaliacaoEditView/ReuniaoGente/Confirm'
          )
        ),
      },
      {
        exact: true,
        path: '/relatorio-apresentacao',
        component: lazy(() =>
          import('src/paginas/relatorio/relatorio-apresentacao')
        ),
        guid: ['de0600376-4f36-4136-b54d-9b865df449bd'],
      },
      {
        exact: true,
        path: '/relatorio',
        component: lazy(() =>
          import('src/paginas/relatorio/relatorio-periodico')
        ),
        guid: ['12a5cbe8-9fb5-4fda-8ec6-13b1367fb3cd'],
      },
      {
        exact: true,
        path: '/relatorio-acompanhamento',
        component: lazy(() =>
          import('src/paginas/relatorio/relatorio-acompanhamento')
        ),
        guid: ['5e8be4c6-abea-49f2-a0c5-e98cc2afdf9d'],
      },
      {
        exact: true,
        path: '/bloco-de-notas',
        component: lazy(() =>
          import('src/paginas/avaliacao-desempenho/BlocoNotas')
        ),
        guid: ['0fa5116b-e0ed-499b-8ba2-6b55c0264776'],
      },
      {
        exact: true,
        path: '/reuniao-gente',
        component: lazy(() =>
          import(
            'src/paginas/avaliacao-desempenho/ReuniaoGente/ReuniaoGenteView'
          )
        ),
        guid: ['1545c4cc-7b31-4f81-99be-5e40a130e39d'],
      },
      {
        exact: true,
        path: '/bloco-de-notas/edit',
        component: lazy(() =>
          import('src/paginas/avaliacao-desempenho/BlocoNotas/Edit')
        ),
      },
      {
        exact: true,
        path: '/elogios',
        component: lazy(() =>
          import('src/paginas/avaliacao-desempenho/Elogios')
        ),
        guid: ['c6b88226-0141-4f50-8ca3-b73d2ae26d22'],
        parameters: { addon: ADDONS.ELOGIOS },
        guard: AddonGuard,
      },
      {
        exact: true,
        path: '/notas-versao',
        component: lazy(() => import('src/paginas/NotasVersao')),
      },
      {
        path: '/resultados',
        routes: [
          {
            exact: true,
            path: '/resultados/dashboard-status-ciclo-avaliativo',
            component: lazy(() =>
              import(
                'src/paginas/avaliacao-desempenho/Resultados/DashboardStatusCicloAvaliativo'
              )
            ),
          },
          {
            exact: true,
            path: '/resultados/dashboard-resultado-ciclo-avaliativo',
            component: lazy(() =>
              import(
                'src/paginas/avaliacao-desempenho/Resultados/DashboardResultadoCicloAvaliativo'
              )
            ),
          },
          {
            exact: true,
            path: '/resultados/meu-desempenho',
            component: lazy(() =>
              import(
                'src/paginas/avaliacao-desempenho/Resultados/Desempenho/MeuDesempenho'
              )
            ),
          },
          {
            exact: true,
            path: '/resultados/desempenho-time',
            component: lazy(() =>
              import(
                'src/paginas/avaliacao-desempenho/Resultados/Desempenho/DesempenhoTime'
              )
            ),
          },
          {
            exact: true,
            path: '/resultados/mapa-sucessao',
            component: lazy(() =>
              import('src/paginas/avaliacao-desempenho/Resultados/MapaSucessao')
            ),
          },
          {
            exact: true,
            path: '/resultados/dashboard-liftbox',
            component: lazy(() =>
              import(
                'src/paginas/avaliacao-desempenho/Resultados/DashboardLiftBox'
              )
            ),
          },
          {
            component: () => <Redirect to="/404" />,
          },
        ],
      },
      {
        path: '/cadastrar',
        routes: [
          {
            exact: true,
            path: '/cadastrar/perfil',
            component: lazy(() => import('src/paginas/perfil')),
          },
          {
            exact: true,
            path: '/cadastrar/perfil/edit',
            component: lazy(() => import('src/paginas/perfil/edit')),
          },
          {
            exact: true,
            path: '/cadastrar/colaborador',
            component: lazy(() => import('src/paginas/colaborador')),
          },
          {
            exact: true,
            path: '/cadastrar/colaborador/perfil',
            component: lazy(() => import('src/paginas/colaborador')),
          },
          {
            exact: true,
            path: '/cadastrar/tipo-cargo',
            component: lazy(() =>
              import('src/paginas/remuneracao-variavel/tipo-cargo')
            ),
            guid: ['87cfce26-f37a-4390-8891-98a429bb484a'],
          },
          {
            exact: true,
            path: '/cadastrar/cargo',
            component: lazy(() =>
              import('src/paginas/remuneracao-variavel/cargo')
            ),
            guid: ['9020079d-4667-4c69-947b-229f9848610d'],
          },
          {
            exact: true,
            path: '/cadastrar/elemento-modelo-avaliacao',
            component: lazy(() =>
              import('src/paginas/avaliacao-desempenho/ElementoModeloAvaliacao')
            ),
            guid: ['0755dad8-90a1-4b7b-b20c-6ae2ad63c40e'],
          },
          {
            exact: true,
            path: '/cadastrar/modelo-avaliacao',
            component: lazy(() =>
              import('src/paginas/avaliacao-desempenho/ModeloAvaliacao')
            ),
            guid: ['1ee75e3f-42ce-47c1-b026-56b728711615'],
          },
          {
            exact: true,
            path: '/cadastrar/modelo-avaliacao/edit',
            component: lazy(() =>
              import(
                'src/paginas/avaliacao-desempenho/ModeloAvaliacao/ModeloAvaliacaoEditView'
              )
            ),
            guid: ['ad091307-d505-4015-ad88-605e231cdf34'],
          },
          {
            exact: true,
            path: '/cadastrar/modelo-checklist',
            component: lazy(() =>
              import('src/paginas/gestao-risco/auditoria/CheckList')
            ),
            guid: ['a6bf51a9-dc2e-42d7-822f-11246fe89c58'],
          },
          {
            exact: true,
            path: '/cadastrar/modelo-checklist/edit',
            component: lazy(() =>
              import(
                'src/paginas/gestao-risco/auditoria/CheckList/ModeloChecklistEditView'
              )
            ),
            guid: ['735099db-f829-4271-be39-dd13f8701e8b'],
          },
          {
            exact: true,
            path: '/cadastrar/ciclo-avaliacao',
            component: lazy(() =>
              import('src/paginas/avaliacao-desempenho/CicloAvaliacao')
            ),
          },
          {
            exact: true,
            path: '/cadastrar/ciclo-avaliacao/edit',
            component: lazy(() =>
              import(
                'src/paginas/avaliacao-desempenho/CicloAvaliacao/CicloAvaliacaoEditView'
              )
            ),
          },
          {
            exact: true,
            path: '/cadastrar/organizacao',
            component: lazy(() => import('src/paginas/organizacao')),
            guid: ['b2720926-7f12-43c9-9154-c71f3ec18686'],
          },
          {
            exact: true,
            path: '/cadastrar/grupo-email',
            component: lazy(() => import('src/paginas/grupo-email')),
            guid: ['57e0e3ea-324f-4066-80f1-091b8eeed4b6'],
          },
          {
            exact: true,
            path: '/cadastrar/desdobramento',
            component: lazy(() => import('src/paginas/desdobramento')),
            guid: ['b75c1e5c-0dec-45e9-8f65-d4b511baac75'],
          },
          {
            exact: true,
            path: '/cadastrar/severidade-risco',
            component: lazy(() =>
              import('src/paginas/gestao-risco/severidade')
            ),
            guid: ['3a66cede-2f19-4b72-82ab-d6cfe06cf64c'],
          },
          {
            path: '/cadastrar/probabilidade-risco',
            component: lazy(() =>
              import('src/paginas/gestao-risco/probabilidade')
            ),
            guid: ['c194020f-0791-465e-82bc-e85043463d87'],
          },
          {
            exact: true,
            path: '/cadastrar/tipo-risco',
            component: lazy(() => import('src/paginas/gestao-risco/tipo')),
            guid: ['cba5f60a-7289-4016-807c-684208bfbf89'],
          },
          {
            exact: true,
            path: '/cadastrar/tratamento-risco',
            component: lazy(() =>
              import('src/paginas/gestao-risco/tratamento')
            ),
            guid: ['15178d46-ed16-4033-a76c-b62cc0b525d4'],
          },
          {
            exact: true,
            path: '/cadastrar/ponto-controle',
            component: lazy(() =>
              import('src/paginas/gestao-risco/ponto-controle')
            ),
            guid: ['c67c50b9-982f-4217-a057-f30d07364727'],
          },
          {
            exact: true,
            path: '/cadastrar/grau-risco',
            component: lazy(() => import('src/paginas/gestao-risco/grau')),
            guid: ['efac1d65-b35d-4dc3-a373-5fcfd99f95a7'],
          },
          {
            exact: true,
            path: '/cadastrar/matriz-risco',
            component: lazy(() =>
              import('src/paginas/gestao-risco/matriz-risco')
            ),
            guid: ['d4d18a5c-f1bf-4503-a4f1-0e4291b7fd0e'],
          },
          {
            exact: true,
            path: '/cadastrar/template-indicador',
            component: lazy(() => import('src/paginas/indicador-template')),
            guid: ['cf6a53a9-8788-41c3-b6ec-ee63770a72e5'],
          },
          {
            exact: true,
            path: '/cadastrar/template-indicador/edit',
            component: lazy(() =>
              import('src/paginas/indicador-template/edit')
            ),
            guid: [
              'fbf181a6-caae-4d06-81b0-2e2f4bd2700f',
              '3bc4954a-c246-4679-941c-668a5262d51c',
            ],
          },
          {
            exact: true,
            path: '/cadastrar/template-parametro',
            component: lazy(() => import('src/paginas/parametro-template')),
            guid: ['420f028d-c195-4c75-a270-6b5f268e0fd7'],
          },
          {
            exact: true,
            path: '/cadastrar/template-parametro/edit',
            component: lazy(() =>
              import('src/paginas/parametro-template/edit')
            ),
            guid: [
              'dd0178b4-20cd-499e-a9cd-0ea4c49c22fb',
              '07b6a972-e521-4943-bcb3-2501d1d91748',
            ],
          },
          {
            exact: true,
            path: '/cadastrar/template-objetivo-estrategico',
            component: lazy(() =>
              import('src/paginas/objetivo-estrategico-template')
            ),
            guid: ['8b915708-887f-4df8-9448-0e277066d1ea'],
          },
          {
            exact: true,
            path: '/cadastrar/template-objetivo-estrategico/edit',
            component: lazy(() =>
              import('src/paginas/objetivo-estrategico-template/edit')
            ),
            guid: [
              'a05ed9f2-76cb-4b0d-841e-78e9e48ac0fa',
              '8a4f171c-6953-4925-8949-23600fd46800',
            ],
          },
          {
            exact: true,
            path: '/cadastrar/fluxo-aprovacao',
            component: lazy(() =>
              import('src/paginas/EscritorioProjetos/FluxoAprovacaoGenerico')
            ),
          },
          {
            exact: true,
            path: '/cadastrar/modelo-visualizacao-comissoes',
            component: lazy(() =>
              import(
                'src/paginas/remuneracao-variavel/ModeloVisualizacaoComissoes'
              )
            ),
          },
          {
            exact: true,
            path: '/cadastrar/tag',
            component: lazy(() => import('src/paginas/tag')),
            guid: [
              '03a6b872-81ee-440a-89f4-5f4202230726',
              '64277edb-570e-4816-a8eb-2c3b2462c6e1',
              'a2430387-6ead-4eca-af38-f5c6dbd75846',
              'a6beae8d-bc12-4d84-bb7b-33e7692f3c37',
              '45870eb2-f43c-4d5c-8d8a-1a748e8b6589',
              '5cf86b22-d81b-46b2-8247-a20abe393a2a',
            ],
          },
          {
            exact: true,
            path: '/cadastrar/workflow-risco',
            component: lazy(() =>
              import('src/paginas/gestao-risco/fluxo-aprovacao')
            ),
            guid: ['15a6b872-18ee-044a-98f4-5f4203320144'],
          },
          {
            exact: true,
            path: '/cadastrar/planodecontas',
            component: lazy(() =>
              import('src/paginas/EscritorioProjetos/PlanodeContas')
            ),
            guid: ['1d0a84bb-1ff9-4313-ad22-37b51c40040d'],
          },
          {
            component: () => <Redirect to="/404" />,
          },
        ],
      },
      {
        path: '/ferramentas',
        routes: [
          {
            path: '/ferramentas/exportar',
            component: lazy(() => import('src/paginas/exportar')),
            guid: [
              '083aa736-8dd1-47a3-8e90-86ac2bf79734',
              '811a1f9c-5dc6-4ec2-bdaf-19a0df6613f7',
              '7a7b55b5-063b-47bd-8627-0c39eb3ee273',
              '24c475fe-f8a2-4cfa-9027-093cd5a9100f',
              'f2ea3a69-fe23-49e4-ad03-2c380ae24abe',
              '8f86739c-a7e4-4e8a-8246-41f911f2d3a7',
              '5063df9b-0e78-46b0-94da-afe7cad3ba1b',
              '81898ee3-56b8-42f6-b30c-2360794141aa',
              'c3c9494b-5d52-4f44-bcdd-be5ad1dfdb9c',
              '72aeb3d2-c7d3-45cb-aa25-e8edcedbf062',
              'b85bd418-94df-467e-9fd3-d4a2d0c85857',
              '36368bb4-0e88-4096-9e8e-0e06f0833460',
              'a2107ebc-d3d8-4615-a48b-97d2cf523367',
              '56e352e2-3e83-4946-a637-22fb078a3fd4',
              'd9d0c290-5e7d-4264-a9b7-867cb4ff25b4',
              'b1f93074-4b3e-4344-abcf-42fe585223c4',
              'dd30664d-2e66-49cc-a2aa-8ce217927b8b',
              'abcb73c5-b921-4a40-a9f6-99f472e9ec61',
              '1e8d25b1-c9ac-4208-908b-1c94c25fa16c',
              'bd20c5e7-017e-43c5-bc40-abb3b876b821',
              '12eb80de-5d7f-490c-9537-64fdee827ca0',
              '12eb80de-5d7f-490c-9537-64fdee827ca0',
              '68211c4c-1e7e-4c29-9605-21a310cbef9c',
              'f7a6ae3c-374d-43c1-8cb7-1e97388897d2',
              'fb92200d-f511-42cf-b622-881983ad1445',
              'd7b56c69-73e5-47f6-98b2-47d7698e7939',
              '280d9f15-362b-4e94-8430-3cf1c526d0a1',
            ],
          },
          {
            path: '"/ferramentas/log-importacoes',
            component: lazy(() => import('src/paginas/log-importacao')),
            guid: ['eb5efae8-a4d3-488a-a768-62642e09e261'],
          },
          {
            exact: true,
            path: '/ferramentas/importar',
            component: lazy(() => import('src/paginas/importar')),
            guid: [
              '795999ec-347c-4e5f-96f8-89895421c1e2',
              '5cf49684-edd6-43f3-b569-cd3e70464150',
              'f8ce761e-05de-4945-9573-d04caa465309',
              'f238dba5-db42-4f46-b86a-a74130091718',
              '035992a7-fc68-4769-a346-802db21ba276',
              'af12ad1b-fac5-40a4-a44c-3f71284f1d3e',
              'f2302b93-8ba8-4059-994d-3566befc5987',
              '6a5ad24a-b1bb-496f-8258-3d7527453eaa',
              '8e12ac10-3d50-4b59-a182-3b57369a3fd0',
              '91f97ab0-09f9-4fbc-94a4-6703d9ef9407',
              '97bd8e6b-98ac-45b5-91e3-2fefeae62fc7',
              'cd96afc6-9163-4746-802a-01a699348809',
              '4a34e7eb-38d0-4b70-af3b-0f0180e1cd3b',
              '12df56e9-ebba-4a24-80bf-1349261edefd',
              '758b5365-7fcf-4115-940e-1fef82d679a6',
              '16c759c0-e37b-4cd5-b6f5-8ed205f21ebd',
              'e9448897-834c-483b-9003-351b579cd857',
              '3e90d368-59a2-40d8-afef-7464a00ded38',
              'ca4eb8cb-2b87-4953-8d8a-3853413b94c2',
              '4e040744-5427-4858-90a6-41faa6d67a8e',
              '0f391c8d-cda4-4431-9726-963683146518',
              '33bbc799-dce3-43bc-bc27-7bc17a08c1df',
              '1847b154-fdd9-4cfb-8b7d-a41dde6997c5',
              '64a2b244-0854-4f7d-9e91-33af91727e84',
              '987105dd-b6d1-438b-b03d-0a7d6a34786f',
              'cf148f08-a641-4c61-94c6-e0251c46d599',
              '7a1dc95a-3e91-45d9-98f5-13fefb04d5bb',
              '8610a24b-bbc0-4977-8731-7b0e8cc413a0',
              'c9117774-ba9b-4ecf-9b8f-d4b8b254b908',
              'f9e266f1-ccda-4c35-b348-1ad169555328',
              'fb26df38-9549-4280-9221-0d6d38474369',
              '007a5b56-6e85-46e7-8370-b5b0ccb08286',
              '6c6d2ca7-cb17-4f84-8985-5e840775c91c',
              'd627d0c8-1ea3-429c-8f28-1526dc62cd51',
              '342e7d20-ce8f-4226-be73-d5cc0a2d72fd',
              '317daa4c-60c4-4962-af8b-2a45131268ce',
              'b73c08be-0aa0-11e9-ab14-d663bd873d93',
              '75766eb0-c796-4f40-8778-71afd3104c1c',
              'ef30c94d-549c-4003-b89f-b9d61a4edf74',
              '214c99e7-cac9-4598-9a89-d94776e86855',
            ],
          },
          {
            exact: true,
            path: '/ferramentas/rotinas',
            component: lazy(() => import('src/paginas/rotinas')),
          },
          {
            exact: true,
            path: '/ferramentas/log',
            component: lazy(() => import('src/paginas/log')),
          },
          {
            exact: true,
            path: '/ferramentas/log-importacoes',
            component: lazy(() => import('src/paginas/log-importacao')),
            guid: ['eb5efae8-a4d3-488a-a768-62642e09e261'],
          },
          {
            exact: true,
            path: '/ferramentas/log-processamentos',
            component: lazy(() => import('src/paginas/log-processamento')),
            guid: ['4d45357e-d170-4dec-85e3-cd63e5d5c611'],
          },
          {
            exact: true,
            path: '/ferramentas/consulta-externa',
            component: lazy(() => import('src/paginas/consulta-externa')),
            guid: ['15c25619-b29d-4bfd-8931-6ca48cf095e1'],
          },
          {
            exact: true,
            path: '/ferramentas/consulta-externa/edit',
            component: lazy(() => import('src/paginas/consulta-externa/edit')),
          },
          {
            exact: true,
            path: '/ferramentas/log-job',
            component: lazy(() => import('src/paginas/log-job')),
          },
          {
            exact: true,
            path: '/ferramentas/historico-acesso',
            component: lazy(() => import('src/paginas/historico-acesso')),
            guid: ['1733b864-8ec9-4b08-ae14-aebd63940cb2'],
          },
          {
            exact: true,
            path: '/ferramentas/transferencia-responsabilidade',
            component: lazy(() =>
              import('src/paginas/transferencia/responsavel')
            ),
            guid: ['5656b745-2000-4111-a0e0-0c2699c5ebec'],
          },
          {
            exact: true,
            path: '/ferramentas/transferencia-area',
            component: lazy(() => import('src/paginas/transferencia/area')),
            guid: ['b91ca82e-c73f-4263-9b45-e6fc254d14fa'],
          },
          {
            exact: true,
            path: '/ferramentas/excluir-itens',
            component: lazy(() => import('src/paginas/excluir-itens')),
            guid: [
              '205eba7d-0817-4532-ac99-8656c5f6423f',
              '7e0c2ecc-3837-4de9-900a-77041220d7d9',
              '705b34f6-cbd5-4150-8af3-ef5d5fded4ea',
              '0614ddec-e0b2-429a-b6e7-9df1775dfeea',
              'b336b8df-7295-4e5f-8af5-b6d043ec75b0',
            ],
          },
          {
            exact: true,
            path: '/ferramentas/integracao',
            component: lazy(() => import('src/paginas/integracao')),
            guid: ['4a6a1e9b-0fd7-4b78-859c-f93997207d67'],
          },
          {
            exact: true,
            path: '/ferramentas/integracao/edit',
            component: lazy(() => import('src/paginas/integracao/edit')),
            guid: ['1157d091-fbc1-4042-8232-8c9eb680bca6'],
          },
          {
            exact: true,
            path: '/ferramentas/excluir-centroscustos',
            component: lazy(() =>
              import('src/paginas/matriz-orcamentaria/excluir-centroscustos')
            ),
            guid: ['039477d7-61f0-49b8-885f-7952682c3d0a'],
          },
          {
            exact: true,
            path: '/ferramentas/excluir-pacotes',
            component: lazy(() =>
              import('src/paginas/matriz-orcamentaria/excluir-pacotes')
            ),
            guid: ['66627cd1-bb1f-4a59-93d8-d892b41db8d1'],
          },
          {
            exact: true,
            path: '/ferramentas/arquivar-acoes',
            component: lazy(() => import('src/paginas/arquivar-acoes')),
            guid: ['1e582837-1c02-4ac4-84a2-6dd6c9ec3fa6'],
          },
          {
            exact: true,
            path: '/ferramentas/log-envio-email',
            component: lazy(() => import('src/paginas/log-envio-email')),
            guid: ['3433d20c-a614-438e-a14d-da0d5b3979ac'],
          },
          {
            exact: true,
            path: '/ferramentas/validacao',
            component: lazy(() => import('src/paginas/validacao')),
          },
          {
            exact: true,
            path: '/ferramentas/comparacao-valores',
            component: lazy(() => import('src/paginas/comparacao-valores')),
          },
          {
            exact: true,
            path: '/ferramentas/gestao-colaboradores',
            component: lazy(() => import('src/paginas/gestao-colaboradores')),
            guid: ['9b491544-432a-48f7-a8cf-707283816837'],
          },
          {
            exact: true,
            path: '/ferramentas/exportar-lift',
            component: lazy(() =>
              import('src/paginas/avaliacao-desempenho/Exportar')
            ),
          },
          {
            exact: true,
            path: '/ferramentas/historico-exportacaojob',
            component: lazy(() =>
              import('src/paginas/historico-exportacaojob')
            ),
            guid: ['be01dcd2-cedc-4d76-8eaa-2101fc3ce751'],
          },
          {
            exact: true,
            path: '/ferramentas/valor-externo',
            component: lazy(() => import('src/paginas/Connector/ValorExterno')),
          },
          {
            exact: true,
            path: '/ferramentas/valor-externo/edit',
            component: lazy(() =>
              import('src/paginas/Connector/ValorExterno/ValorExternoEditView')
            ),
          },
          {
            exact: true,
            path: '/ferramentas/fonte-dados/edit',
            component: lazy(() =>
              import('src/paginas/Connector/FonteDados/FonteDadosEditView')
            ),
          },
          {
            exact: true,
            path: '/ferramentas/parametro-externo/edit',
            component: lazy(() =>
              import(
                'src/paginas/Connector/ValorExterno/ParametroExternoEditView'
              )
            ),
          },
          {
            exact: true,
            path: '/ferramentas/connector',
            component: lazy(() =>
              import('src/paginas/Connector/ConnectorListView')
            ),
            parameters: { addon: ADDONS.CONNECTOR },
            guard: AddonGuard,
          },
          {
            exact: true,
            path: '/ferramentas/connector/edit',
            component: lazy(() =>
              import('src/paginas/Connector/ConnectorEditView')
            ),
          },
          {
            exact: true,
            path: '/ferramentas/connectorendpoint',
            component: lazy(() =>
              import('src/paginas/Connector/ConnectorEndPointListView')
            ),
          },
          {
            exact: true,
            path: '/ferramentas/connectorendpoint/edit',
            component: lazy(() =>
              import('src/paginas/Connector/ConnectorEditView')
            ),
          },
          {
            exact: true,
            path: '/ferramentas/versao',
            component: lazy(() => import('src/paginas/versao/VersionInfo')),
          },
          {
            exact: true,
            path: '/ferramentas/webhooks',
            component: lazy(() => import('src/paginas/Webhooks')),
          },
          {
            component: () => <Redirect to="/404" />,
          },
        ],
      },
      {
        path: '/configuracoes',
        routes: [
          {
            exact: true,
            path: '/configuracoes/geral',
            component: lazy(() => import('src/paginas/configuracao-geral')),
          },
          {
            exact: true,
            path: '/configuracoes/auditoria-risco',
            component: lazy(() =>
              import('src/paginas/gestao-risco/auditoria/configuracao')
            ),
            parameters: { addon: ADDONS.AUDITORIA_RISCO },
            guard: AddonGuard,
          },
          {
            exact: true,
            path: '/configuracoes/checklist-risco',
            component: lazy(() =>
              import(
                'src/paginas/gestao-risco/auditoria/CheckList/Configuracao'
              )
            ),
            parameters: { addon: ADDONS.AUDITORIA_RISCO },
            guard: AddonGuard,
          },
          {
            exact: true,
            path: '/configuracoes/colaborador',
            component: lazy(() =>
              import('src/paginas/avaliacao-desempenho/ConfiguracaoColaborador')
            ),
          },
          {
            exact: true,
            path: '/configuracoes/geral',
            component: lazy(() => import('src/paginas/configuracao-geral')),
            guid: ['aa49ace9-e529-43d4-899a-48ea65d16cc3'],
          },
          {
            exact: true,
            path: '/configuracoes/indicador',
            component: lazy(() =>
              import('src/paginas/indicador/configuracao-geral')
            ),
            guid: ['03707276-a4df-43d0-ab89-70c9de26e72e'],
          },
          {
            exact: true,
            path: '/configuracoes/csa',
            component: lazy(() =>
              import('src/paginas/gestao-risco/CSA/Configuracao')
            ),
            guid: ['fd1e1c60-e1c2-4ce7-8513-f932e9d2a7df'],
            parameters: { addon: ADDONS.CONTROL_SELF_ASSESSMENT },
            guard: AddonGuard,
          },
          {
            exact: true,
            path: '/configuracoes/parametro',
            component: lazy(() =>
              import('src/paginas/parametro/configuracao-geral')
            ),
            guid: ['14848015-dae7-4e99-988e-fda9e9c4807b'],
          },
          {
            exact: true,
            path: '/configuracoes/objetivo-estrategico',
            component: lazy(() =>
              import('src/paginas/objetivo-estrategico/configuracao-geral')
            ),
            guid: ['15505a9a-bbbe-42a0-8373-03b3fab9e616'],
          },
          {
            exact: true,
            path: '/configuracoes/agenda',
            component: lazy(() =>
              import('src/paginas/agenda/configuracao-geral')
            ),
          },
          {
            exact: true,
            path: '/configuracoes/projeto',
            component: lazy(() =>
              import('src/paginas/projeto/configuracao-geral')
            ),
            guid: ['9d91db2b-af95-490e-b9ff-3aabcd60a3dd'],
          },
          {
            exact: true,
            path: '/configuracoes/acao',
            component: lazy(() =>
              import('src/paginas/acao/configuracao-geral')
            ),
            guid: ['67401a70-fb46-4922-aa75-2086aa4ea344'],
          },
          {
            exact: true,
            path: '/configuracoes/calendario',
            component: lazy(() => import('src/paginas/calendario')),
            guid: [''],
          },
          {
            exact: true,
            path: '/configuracoes/relatorio-acompanhamento',
            component: lazy(() =>
              import('src/paginas/relatorio-acompanhamento/configuracao-geral')
            ),
            guid: ['d65b1ef3-0289-4877-b4de-bd4bd1d54202'],
          },
          {
            exact: true,
            path: '/configuracoes/reuniao',
            component: lazy(() =>
              import('src/paginas/reuniao/configuracao-geral')
            ),
            guid: ['5c0a3301-2895-4a2a-9122-667b72b85462'],
          },
          {
            exact: true,
            path: '/configuracoes/risco',
            component: lazy(() =>
              import('src/paginas/gestao-risco/configuracao')
            ),
            guid: ['48cb519a-5fd6-4e8f-912f-2d92e1cf1434'],
          },
          {
            exact: true,
            path: '/configuracoes/ponto-controle',
            component: lazy(() =>
              import('src/paginas/gestao-risco/ponto-controle/configuracao')
            ),
            guid: ['cf13365f-4838-4db4-a3e0-dc1ef55c5bbc'],
          },
          {
            exact: true,
            path: '/configuracoes/processo',
            component: lazy(() =>
              import('src/paginas/gestao-risco/processo/configuracao')
            ),
            guid: ['85e45f17-d083-47ee-b07f-18d9830b2be7'],
          },
          {
            exact: true,
            path: '/configuracoes/bi',
            component: lazy(() => import('src/paginas/power-bi')),
            guid: ['bcde2b41-1e21-4032-bbd3-b9dac89d693e'],
          },
          {
            exact: true,
            path: '/configuracoes/consultora-virtual',
            component: lazy(() => import('src/paginas/consultora-virtual')),
            guid: ['76ac127a-5014-4468-8cdf-4da4b3d830ae'],
          },
          {
            exact: true,
            path: '/configuracoes/ms-graph',
            component: lazy(() => import('src/paginas/ms-graph')),
            guid: ['e5ca97bc-4c74-416e-a508-59fb5adf4ce7'],
          },
          {
            path: '/configuracoes/cardmeta',
            component: lazy(() =>
              import('src/paginas/remuneracao-variavel/CardMeta/Configurations')
            ),
            guid: ['0c22ae7e-2cb4-41cd-86bf-d0196a205d33'],
          },
          {
            path: '/configuracoes/comissoes',
            component: lazy(() =>
              import('src/paginas/remuneracao-variavel/ConfiguracaoComissoes')
            ),
            guid: ['46a5fbb5-b2c2-4b81-862b-6d13ba8fd72b'],
          },
          {
            exact: true,
            path: '/configuracoes/indicador-risco',
            component: lazy(() =>
              import(
                'src/paginas/gestao-risco/indicador-risco/configuracao-geral'
              )
            ),
            guid: ['b4c210db-6354-488d-ac84-96ea7ce478e6'],
          },
          {
            exact: true,
            path: '/cadastrar/efetividade-controle-risco/edit',
            component: lazy(() =>
              import(
                'src/paginas/gestao-risco/risco/RevisaoPontoControle/EfetividadeControle/EfetividadeControleEdit'
              )
            ),
            guid: ['18391524-c5c2-4ac1-9c5f-ff20d739992d'],
          },
          {
            path: '/configuracoes/okr',
            component: lazy(() => import('src/paginas/okr/configuracao')),
            guid: ['0144f2e2-f971-4fa5-a623-b3b78fe423c8'],
          },
          {
            path: '/configuracoes/ciclo-avaliacao',
            component: lazy(() =>
              import(
                'src/paginas/avaliacao-desempenho/CicloAvaliacao/ConfiguracaoGeral'
              )
            ),
            guid: ['2c6e7ea5-fa38-4a99-a144-19b52f79cce5'],
          },
          {
            path: '/configuracoes/modelo-avaliacao',
            component: lazy(() =>
              import(
                'src/paginas/avaliacao-desempenho/ModeloAvaliacao/ConfiguracaoGeral'
              )
            ),
            guid: ['2c6e7ea5-fa38-4a99-a144-19b52f79cce5'],
          },
          {
            path: '/configuracoes/meta-individual',
            component: lazy(() =>
              import(
                'src/paginas/avaliacao-desempenho/MetaIndividual/configuracao'
              )
            ),
            guid: ['9a82225a-c483-4847-a967-49dadaac8932'],
          },
          {
            path: '/configuracoes/meu-pdi',
            component: lazy(() =>
              import('src/paginas/avaliacao-desempenho/PDI/Configuracao')
            ),
            guid: ['27cf9e5e-9ae9-47d2-9061-310b5148208c'],
          },
          {
            path: '/configuracoes/elogios',
            component: lazy(() =>
              import('src/paginas/avaliacao-desempenho/Elogios/Configuracao')
            ),
            guid: ['10778cca-6398-4a92-8ee4-651c4b637d0a'],
            parameters: { addon: ADDONS.ELOGIOS },
            guard: AddonGuard,
          },
          {
            exact: true,
            path: '/configuracoes/feedback-continuo',
            component: lazy(() =>
              import(
                'src/paginas/avaliacao-desempenho/FeedbackContinuo/Configuracao'
              )
            ),
            parameters: { addon: ADDONS.FEEDBACK_CONTINUO },
            guard: AddonGuard,
            guid: ['73a8980a-519f-434f-a12b-84484c0b8973'],
          },
          {
            exact: true,
            path: '/configuracoes/feedback-continuo/tema/edit',
            component: lazy(() =>
              import(
                'src/paginas/avaliacao-desempenho/FeedbackContinuo/Configuracao/Temas/CriarTemas/Edit'
              )
            ),
            parameters: { addon: ADDONS.FEEDBACK_CONTINUO },
            guard: AddonGuard,
            guid: ['a97d7503-b8ad-463d-9561-799bc91ee894'],
          },
          {
            exact: true,
            path: '/configuracoes/csa/template/edit',
            component: lazy(() =>
              import(
                'src/paginas/gestao-risco/CSA/Configuracao/Menu/Templates/Edit'
              )
            ),
            guid: ['f621c5ce-27ce-4c17-bf7c-3e75f6ecd0ea'],
            parameters: { addon: ADDONS.CONTROL_SELF_ASSESSMENT },
            guard: AddonGuard,
          },
          {
            exact: true,
            path: '/configuracoes/gesta-documentos',
            component: lazy(() =>
              import('src/paginas/GestaoDocumentos/configuracao')
            ),
            parameters: { addon: ADDONS.GESTAO_DOCUMENTOS },
            guard: AddonGuard,
          },
          {
            component: () => <Redirect to="/404" />,
          },
        ],
      },

      {
        path: '/minhas-informacoes',
        routes: [
          {
            path: '/minhas-informacoes/notificacao',
            component: lazy(() => import('src/paginas/notificacao')),
          },
          {
            path: '/minhas-informacoes/meus-dados',
            component: lazy(() => import('src/paginas/colaborador/MeusDados')),
          },
          {
            exact: true,
            path: '/minhas-informacoes/mensagem',
            component: lazy(() => import('src/paginas/mensagem')),
          },
          {
            component: () => <Redirect to="/404" />,
          },
        ],
      },

      {
        path: '/painel',
        routes: [
          {
            exact: true,
            path: '/painel/dashboard-individual',
            component: lazy(() => import('src/pages/Dashboard/Container')),
          },
          {
            exact: true,
            path: '/painel/dashboard',
            component: lazy(() =>
              import('src/paginas/dashboard-custom/view-page')
            ),
          },
          {
            exact: true,
            path: '/painel/mapa-estrategico',
            component: lazy(() =>
              import('src/paginas/mapa-estrategico/view-page')
            ),
          },
          {
            component: () => <Redirect to="/404" />,
          },
        ],
      },

      {
        path: '/minhas-tarefas',
        routes: [
          {
            exact: true,
            path: '/minhas-tarefas/atualizar-acao',
            component: lazy(() => import('src/paginas/acao/atualizar-acao')),
          },
          {
            exact: true,
            path: '/minhas-tarefas/valor-desatualizado',
            component: lazy(() =>
              import('src/paginas/item/valor/valor-desatualizado')
            ),
          },
          {
            exact: true,
            path: '/minhas-tarefas/validar-valor',
            component: lazy(() =>
              import('src/paginas/item/valor/valor-aprovacao')
            ),
          },
          {
            exact: true,
            path: '/minhas-tarefas/validar-acao',
            component: lazy(() => import('src/paginas/acao/validacao')),
          },
          {
            exact: true,
            path: '/minhas-tarefas/minha-agenda',
            component: lazy(() => import('src/paginas/AgendaColaborador')),
          },
          {
            exact: true,
            path: '/minhas-tarefas/avaliacao-pendente',
            component: lazy(() =>
              import(
                'src/paginas/gestao-risco/risco/Avaliacao/AvaliacaoPendenteListView'
              )
            ),
          },
          {
            exact: true,
            path: '/minhas-tarefas/revisao-pendente',
            component: lazy(() =>
              import(
                'src/paginas/gestao-risco/risco/RevisaoPontoControle/RevisoesPendentesListView'
              )
            ),
          },
          {
            exact: true,
            path: '/minhas-tarefas/validacao-pendente',
            component: lazy(() =>
              import(
                'src/paginas/gestao-risco/risco/RevisaoPontoControle/ValidacoesPendentesListView'
              )
            ),
          },
          {
            exact: true,
            path: '/minhas-tarefas/atualizar-valores-kri',
            component: lazy(() =>
              import(
                'src/paginas/item/valor/valor-desatualizado-indicador-risco'
              )
            ),
          },
          {
            exact: true,
            path: '/minhas-tarefas/aprovar-projetos',
            component: lazy(() =>
              import('src/paginas/EscritorioProjetos/AprovacaoProjetos')
            ),
          },
          {
            exact: true,
            path: '/minhas-tarefas/aprovar-acoes',
            component: lazy(() =>
              import('src/paginas/acao/AcaoAprovacao/Aprovacao')
            ),
          },
          {
            exact: true,
            path: '/minhas-tarefas/gestao-mudancas',
            component: lazy(() =>
              import('src/paginas/EscritorioProjetos/GestaoMudancas')
            ),
            guid: [
              'fbafca05-53b3-4550-96a7-2ced3ccab109',
              '6620e797-0fda-49bb-96e0-0d47953aff3c',
              'a6de90c2-b420-4691-b909-40c612795997',
              'c5c6a1ca-45ca-498e-890e-c90cfc441ce9',
              '1de92b1b-c70a-4150-afa7-ed07695d671b',
              'c92ab756-3e13-4575-81b8-4285f6576bca',
              '0abc92aa-3cf3-45dc-94a3-d7b66b7e29c8',
              '373fe691-6520-44bf-962a-66e7d95a8b83',
            ],
          },
          {
            exact: true,
            path: '/minhas-tarefas/gestao-mudancas-risco',
            component: lazy(() =>
              import('src/paginas/gestao-risco/fluxo-aprovacao/GestaoMudancas')
            ),
            guid: [
              'fbafca05-53b3-4550-96a7-2ced3ccab109',
              '6620e797-0fda-49bb-96e0-0d47953aff3c',
              'a6de90c2-b420-4691-b909-40c612795997',
              'c5c6a1ca-45ca-498e-890e-c90cfc441ce9',
              '1de92b1b-c70a-4150-afa7-ed07695d671b',
              'c92ab756-3e13-4575-81b8-4285f6576bca',
              '0abc92aa-3cf3-45dc-94a3-d7b66b7e29c8',
              '373fe691-6520-44bf-962a-66e7d95a8b83',
            ],
          },
          {
            component: () => <Redirect to="/404" />,
          },
        ],
      },

      {
        path: '/acompanhar',
        routes: [
          {
            exact: true,
            path: '/acompanhar/matriz-risco',
            component: lazy(() =>
              import('src/paginas/gestao-risco/matriz-risco/matriz-farol')
            ),
            guid: ['d4d18a5c-f1bf-4503-a4f1-0e4291b7fd0e'],
          },
          {
            exact: true,
            path: '/acompanhar/matriz-risco-itens-impactados',
            component: lazy(() =>
              import(
                'src/paginas/gestao-risco/matriz-risco/matriz-itens-impactados'
              )
            ),
            guid: ['1f3fa68d-6c03-443a-b05b-aa3759b820c7'],
          },
          {
            exact: true,
            path: '/acompanhar/matriz-resultado',
            component: lazy(() => import('src/paginas/matriz-resultado')),
            guid: ['420b83c8-3418-41d4-bc44-23a1e97abaa9'],
          },
          {
            exact: true,
            path: '/acompanhar/tema-estrategico',
            component: lazy(() =>
              import('src/paginas/tema-estrategico/tema-itens')
            ),
            guid: ['25586cdb-e1f6-45e5-9771-eef64d19e0f9'],
          },
          {
            exact: true,
            path: '/acompanhar/grafico-desvio',
            component: lazy(() => import('src/paginas/grafico-desvio')),
            guid: ['81fe02b3-2b69-46e2-aa2b-4721b6c97583'],
          },
          {
            exact: true,
            path: '/acompanhar/grafico-comparativo',
            component: lazy(() => import('src/paginas/grafico-comparativo')),
            guid: ['0f4e833f-b8c1-442a-a767-a50bb574c5d0'],
          },
          {
            exact: true,
            path: '/acompanhar/grafico-comparativo/edit',
            component: lazy(() =>
              import('src/paginas/grafico-comparativo/edit')
            ),
            guid: ['73f498c9-06a1-4e36-aaab-1fe896307e6b'],
          },
          {
            exact: true,
            path: '/acompanhar/grafico-comparativo/grafico',
            component: lazy(() =>
              import('src/paginas/grafico-comparativo/view-page')
            ),
            guid: ['0f4e833f-b8c1-442a-a767-a50bb574c5d0'],
          },
          {
            exact: true,
            path: '/acompanhar/painel',
            component: lazy(() => import('src/paginas/painel/view-page')),
            guid: ['0ec2c22e-df7d-41c2-8017-694422804a52'],
          },
          {
            exact: true,
            path: '/acompanhar/apresentacao',
            component: lazy(() => import('src/paginas/apresentacao/view-page')),
            guid: ['9f4199b7-d0b9-4e26-a348-592610db39a8'],
          },
          {
            exact: true,
            path: '/acompanhar/resultado-area',
            component: lazy(() => import('src/paginas/resultado-area')),
            guid: ['9953e33c-44c9-4329-a3c2-7b4eb6d9bad4'],
          },
          {
            exact: true,
            path: '/acompanhar/relatorio-periodico',
            component: lazy(() =>
              import('src/paginas/relatorio-periodico/versao')
            ),
            guid: [
              'd1562b2a-8ae3-45c1-8fc6-490599e80534',
              '11dcff25-df66-4fbe-818f-e7f87853d434',
            ],
          },
          {
            exact: true,
            path: '/acompanhar/relatorio-periodico/edit',
            component: lazy(() =>
              import('src/paginas/relatorio-periodico/versao-edit')
            ),
            guid: [
              '7f7195ec-1579-47b6-9dd3-cf3c6cf94d59',
              '18333945-d630-4a03-a8e8-1742c288c22f',
              '11dcff25-df66-4fbe-818f-e7f87853d434',
            ],
          },
          {
            exact: true,
            path: '/acompanhar/resultado-item',
            component: lazy(() => import('src/paginas/resultado-item')),
            guid: ['528306ac-6719-42d6-8ca6-723fb6951133'],
          },
          {
            exact: true,
            path: '/acompanhar/valores-desatualizados',
            component: lazy(() =>
              import('src/paginas/item/valor/valor-desatualizado')
            ),
            guid: ['eca51d1c-6afe-45c6-8011-98e0bf99088c'],
          },
          {
            exact: true,
            path: '/acompanhar/mapa-comparativo',
            component: lazy(() =>
              import('src/paginas/item/mapa-comparativo/view-map')
            ),
            guid: ['564b5782-4a15-4337-8cad-ad964e1493b2'],
          },
          {
            exact: true,
            path: '/acompanhar/acoes',
            component: lazy(() => import('src/paginas/acoes')),
            guid: [
              'bb405349-1290-42b3-a6db-808c13ec205e',
              'f6c6f626-004c-47fc-832a-cc2081931d96',
            ],
          },
          {
            exact: true,
            path: '/acompanhar/projetos',
            component: lazy(() => import('src/paginas/painel-projetos')),
            guid: ['015cf4c8-5d55-4f1c-bd0d-5690d7f939ea'],
          },
          {
            exact: true,
            path: '/acompanhar/ganhos-por-acao',
            component: lazy(() =>
              import('src/paginas/acao/ganhos-por-acao/view')
            ),
            guid: ['fe61de57-a775-4e40-82b1-0b591a5e094e'],
          },
          {
            exact: true,
            path: '/acompanhar/anexos',
            component: lazy(() => import('src/paginas/anexos')),
            guid: ['12f7a410-9613-4763-9872-20238ff6a058'],
          },
          {
            exact: true,
            path: '/acompanhar/organograma',
            component: lazy(() => import('src/paginas/item/organograma/view')),
          },
          {
            exact: true,
            path: '/acompanhar/estatisticas',
            component: lazy(() => import('src/paginas/estatisticas/view')),
            guid: ['2a8fd9cd-572a-44e2-94bb-d2c5f2ef7626'],
          },
          {
            exact: true,
            path: '/acompanhar/valor-parametro-projeto',
            component: lazy(() =>
              import('src/paginas/projeto/valores/parametros')
            ),
            guid: ['7363d427-5a15-4744-90c8-659be7dff7f7'],
          },
          {
            exact: true,
            path: '/acompanhar/valor-indicador-projeto',
            component: lazy(() =>
              import('src/paginas/projeto/valores/indicadores')
            ),
            guid: ['3413c91c-6803-4c51-b6e2-901e384a6c81'],
          },
          {
            exact: true,
            path: '/acompanhar/matriz-portfolio-projetos',
            component: lazy(() =>
              import(
                'src/paginas/EscritorioProjetos/PortfolioProjetos/Dashboard'
              )
            ),
          },
          {
            exact: true,
            path: '/acompanhar/matriz-responsabilidades',
            component: lazy(() =>
              import(
                'src/paginas/EscritorioProjetos/MatrizResponsabilidades/Dashboard'
              )
            ),
          },
          {
            exact: true,
            path: '/acompanhar/relatoriobsc',
            component: lazy(() => import('src/paginas/relatoriobsc')),
            guid: ['c3e2989d-9e99-4e33-a1ff-49d019bb1ed9'],
          },
          {
            exact: true,
            path: '/acompanhar/planejamento-inicial',
            component: lazy(() => import('src/paginas/PlanejamentoInicial')),
          },
          {
            exact: true,
            path: '/acompanhar/valor-planocontas-projeto',
            component: lazy(() =>
              import('src/paginas/EscritorioProjetos/PlanodeContas/Valores')
            ),
          },
          {
            component: () => <Redirect to="/404" />,
          },
        ],
      },

      {
        path: '/gerenciar',
        routes: [
          {
            exact: true,
            path: '/gerenciar/auditoria-risco',
            component: lazy(() => import('src/paginas/gestao-risco/auditoria')),
            guid: [''],
          },
          {
            exact: true,
            path: '/gerenciar/auditoria-risco/edit',
            component: lazy(() => import('src/paginas/gestao-risco/auditoria')),
          },
          {
            exact: true,
            path: '/gerenciar/checklist-risco',
            component: lazy(() =>
              import('src/paginas/gestao-risco/auditoria/CheckList')
            ),
            guid: [''],
          },
          {
            exact: true,
            path: '/gerenciar/arvoreCentroCusto',
            component: lazy(() =>
              import('src/paginas/matriz-orcamentaria/centro-custo/view-page')
            ),
            guid: ['480eb1f0-1c4f-40e1-ac3d-d477dd54faa6'],
          },
          {
            exact: true,
            path: '/gerenciar/valor',
            component: lazy(() => import('src/paginas/item/valor')),
            guid: [''],
          },
          {
            exact: true,
            path: '/gerenciar/arvore',
            component: lazy(() =>
              import('src/paginas/item/relacionamento/view-page')
            ),
            guid: [''],
          },
          {
            exact: true,
            path: '/gerenciar/arvoreArea',
            component: lazy(() => import('src/paginas/organizacao/view-page')),
            guid: ['b2720926-7f12-43c9-9154-c71f3ec18686'],
          },
          {
            exact: true,
            path: '/gerenciar/mapa-estrategico/edit',
            component: lazy(() => import('src/paginas/mapa-estrategico/edit')),
            guid: ['e287651b-bf73-4ef9-ba4a-17699d0ab393'],
          },
          {
            exact: true,
            path: '/gerenciar/mapa-estrategico',
            component: lazy(() => import('src/paginas/mapa-estrategico')),
            guid: ['15a4d46d-4c85-46ea-83db-19b6ce85a15e'],
          },
          {
            exact: true,
            path: '/gerenciar/dashboard/edit',
            component: lazy(() => import('src/paginas/dashboard-custom/edit')),
            guid: [
              '14285534-983b-4549-9279-d8a35bfef970',
              'eade67cd-8e26-4726-9118-488841530f5e',
            ],
          },
          {
            exact: true,
            path: '/gerenciar/dashboard',
            component: lazy(() => import('src/paginas/dashboard-custom')),
            guid: ['687d1816-fe65-42c7-bacd-d32d43627aaf'],
          },
          {
            exact: true,
            path: '/gerenciar/relatorio-periodico/edit',
            component: lazy(() =>
              import('src/paginas/relatorio-periodico/edit')
            ),
            guid: [
              '7f7195ec-1579-47b6-9dd3-cf3c6cf94d59',
              '77f700d6-32e9-40c4-af5a-d07d72e5c8d1',
            ],
          },
          {
            exact: true,
            path: '/gerenciar/relatorio-periodico',
            component: lazy(() => import('src/paginas/relatorio-periodico')),
            guid: ['d1562b2a-8ae3-45c1-8fc6-490599e80534'],
          },
          {
            exact: true,
            path: '/gerenciar/indicador',
            component: lazy(() => import('src/paginas/indicador')),
            guid: ['9bc24cc8-e61d-4f0e-90ce-8288a6e1c0f6'],
          },
          {
            exact: true,
            path: '/gerenciar/indicador/edit',
            component: lazy(() => import('src/paginas/indicador/edit')),
            guid: [
              '9bc24cc8-e61d-4f0e-90ce-8288a6e1c0f6',
              '74f41d73-eb7c-462d-b670-170aa03b44fb',
              '20509285-1cb6-4c0b-afe6-dfcc287ff88e',
              'd4ad3040-1bd9-4d95-8c41-ad8ef4f4d277',
              'f46bf02a-c21f-4603-ba1a-069451263b95',
              'ead6cdb6-1789-4400-be4e-34fac55caf8b',
              'ef6af307-01e6-4039-9fbe-24e30338a250',
            ],
          },
          {
            exact: true,
            path: '/gerenciar/parametro',
            component: lazy(() => import('src/paginas/parametro')),
            guid: ['ea688620-9064-41d4-8558-ffb58e2838ca'],
          },
          {
            exact: true,
            path: '/gerenciar/parametro/edit',
            component: lazy(() => import('src/paginas/parametro/edit')),
            guid: [
              'ea688620-9064-41d4-8558-ffb58e2838ca',
              'f22566eb-15db-4061-a320-393be9f0f9f1',
              '5d029524-3761-4216-abaa-d4a441834d94',
              'cd85a5c7-5cb1-43ca-b976-46e613e96700',
              '094fe923-088d-41e7-bff8-f3703e710a6f',
              'e5423136-e092-40c6-9212-23fe32fb2808',
              '8a208b62-e45f-4cbe-8e4e-95ceba48f301',
            ],
          },
          {
            exact: true,
            path: '/gerenciar/objetivo-estrategico',
            component: lazy(() => import('src/paginas/objetivo-estrategico')),
            guid: ['074a52fc-bc93-4e05-b816-62a024f30895'],
          },
          {
            exact: true,
            path: '/gerenciar/objetivo-estrategico/edit',
            component: lazy(() =>
              import('src/paginas/objetivo-estrategico/edit')
            ),
            guid: [
              '074a52fc-bc93-4e05-b816-62a024f30895',
              'ed9de505-bc8b-471d-80fe-3848f4edb25e',
              '9a2895ad-0166-425a-8246-db8bfec16f07',
              '916dde64-2f04-4663-90f1-7e1023e62c82',
              'ca07c719-e91a-4b5d-a8e5-475e79d89d04',
              '1780785d-50bf-49ae-ae52-3c59fc2bfcde',
              '203d22d4-840e-4bf0-b45a-ecd79ac1ae63',
            ],
          },
          {
            exact: true,
            path: '/gerenciar/reuniao',
            component: lazy(() => import('src/paginas/reuniao')),
            guid: ['5f2f8481-c520-40c7-afef-bad3e072b88c'],
          },
          {
            exact: true,
            path: '/gerenciar/projeto',
            component: lazy(() => import('src/paginas/projeto')),
            guid: ['015cf4c8-5d55-4f1c-bd0d-5690d7f939ea'],
          },
          {
            exact: true,
            path: '/gerenciar/projeto/edit',
            component: lazy(() => import('src/paginas/projeto/edit')),
            guid: [
              '8dce8911-a871-4b6f-9cea-89dff5272f9d',
              '4a371f6c-f1d3-4563-a1ed-d5018a576d3d',
              '467723ce-316e-4dda-9846-1582a46f3261',
              '9dc9008f-71b2-4890-9858-f5d01977497f',
              '96d1b691-743b-4534-b89e-a0c0a558f9d3',
              'c1f5e897-dcce-4968-8b67-640ee9b49a13',
              'ac177e63-eaa6-4755-8719-497655f0c8ee',
              'f27cf347-f397-4fc8-a892-b4cee53ca99c',
              'fa7f85d8-3f40-4fdb-ae7e-a7c2f00cd08d',
              '015cf4c8-5d55-4f1c-bd0d-5690d7f939ea',
            ],
          },
          {
            exact: true,
            path: '/gerenciar/projeto/resumo',
            component: lazy(() => import('src/paginas/projeto/Resumo')),
          },
          {
            exact: true,
            path: '/gerenciar/agenda',
            component: lazy(() => import('src/paginas/agenda')),
            guid: ['7973949d-cbe8-4ba3-8134-a952ef2662d4'],
          },
          {
            exact: true,
            path: '/gerenciar/acao',
            component: lazy(() => import('src/paginas/acao')),
            guid: [
              '94dc6406-1b9c-409a-9168-080b080705f7',
              'f6c6f626-004c-47fc-832a-cc2081931d96',
              'abe62640-9be4-47d1-9ddd-48c15e161102',
              '9c8b462e-d730-4ffa-aad2-1cbd782c1658',
              '03893e26-44ee-40bf-8935-8b31d2774192',
              'ce3de81f-cfac-4bbd-9ce8-9cfc303f1e12',
              '3090ee64-6072-4a22-a5d2-a7030712e166',
              'd50fab14-c366-4f5f-bc1e-1633339cbd41',
              '4d6b8048-b05b-487b-b029-35344f3f5b5c',
              '30ba96d5-e693-4357-963c-4540df15f4dd',
              '9fd2e88d-1b18-41bc-8036-36aee8da355b',
              '47abb95d-7fe8-458d-9762-b6b6c52fb2b7',
              '5d0e3541-67d6-4143-b0e0-f2141e6f864b',
              '89fbf7b3-4aaa-4861-9194-498c99dc9bbf',
              'bb405349-1290-42b3-a6db-808c13ec205e',
            ],
          },
          {
            exact: true,
            path: '/gerenciar/gantt',
            component: lazy(() => import('src/paginas/NewGantt')),
          },
          {
            exact: true,
            path: '/gerenciar/relatorio-acompanhamento',
            component: lazy(() =>
              import('src/paginas/relatorio-acompanhamento')
            ),
            guid: [
              '12333643-67ba-46fa-a4cd-d7e0f23ba7d7',
              '18e627b5-bc48-497b-9274-89fc68fdfb27',
            ],
          },
          {
            exact: true,
            path: '/gerenciar/painel',
            component: lazy(() => import('src/paginas/painel')),
            guid: ['0ec2c22e-df7d-41c2-8017-694422804a52'],
          },
          {
            exact: true,
            path: '/gerenciar/painel/edit',
            component: lazy(() => import('src/paginas/painel/edit')),
            guid: ['ed445b64-0869-41e5-bf03-cdc3115d6213'],
          },
          {
            exact: true,
            path: '/gerenciar/apresentacao',
            component: lazy(() => import('src/paginas/apresentacao')),
            guid: ['9f4199b7-d0b9-4e26-a348-592610db39a8'],
          },
          {
            exact: true,
            path: '/gerenciar/apresentacao/edit',
            component: lazy(() => import('src/paginas/apresentacao/edit')),
            guid: ['e0357a51-e7ae-460c-942e-237657768e1b'],
          },
          {
            exact: true,
            path: '/gerenciar/gestao-a-vista',
            component: lazy(() => import('src/paginas/gestao-a-vista')),
            guid: ['6dac0da6-1ce9-4267-aae1-556ca33c918f'],
          },
          {
            exact: true,
            path: '/gerenciar/gestao-a-vista/edit',
            component: lazy(() => import('src/paginas/gestao-a-vista/edit')),
            guid: ['5c88b8a1-e82c-4618-bc28-d2ce23cf3c5d'],
          },
          {
            exact: true,
            path: '/gerenciar/mapa-comparativo',
            component: lazy(() => import('src/paginas/item/mapa-comparativo')),
            guid: ['564b5782-4a15-4337-8cad-ad964e1493b2'],
          },
          {
            exact: true,
            path: '/gerenciar/mapa-comparativo/edit',
            component: lazy(() =>
              import('src/paginas/item/mapa-comparativo/edit')
            ),
          },
          {
            exact: true,
            path: '/gerenciar/risco',
            component: lazy(() =>
              import('src/paginas/gestao-risco/risco/RiscoListView')
            ),
            guid: ['d1975929-f1ee-4a85-9f07-1a49564700a8'],
          },
          {
            exact: true,
            path: '/gerenciar/risco/edit',
            component: lazy(() =>
              import('src/paginas/gestao-risco/risco/RiscoEditView')
            ),
            guid: [
              '9e12451e-9f06-4a48-a453-33baf4cc0f66',
              'c91b380d-acde-4445-a0b9-aedcd2ad6996',
              '743f3577-78bb-4b3e-a15c-6a4c75d504c9',
              '11dff9c2-72e1-4420-9333-04446ef48b03',
              '71c3506c-97b9-4663-98b8-a063b66f33e1',
              'a1efbf1a-f6e3-4007-8ddd-01b45862cf24',
            ],
          },
          {
            exact: true,
            path: '/gerenciar/plano-contigencia',
            component: lazy(() =>
              import(
                'src/paginas/gestao-risco/plano-contigencia/PlanoContigenciaView'
              )
            ),
            guid: ['b0907cbc-33e0-48b4-839a-a9b453f8d215'],
          },
          {
            exact: true,
            path: '/gerenciar/csa',
            component: lazy(() =>
              import('src/paginas/gestao-risco/CSA/CSAView')
            ),
            guid: ['38a8e95b-ad72-4113-836e-ceebf40b2530'],
            parameters: { addon: ADDONS.CONTROL_SELF_ASSESSMENT },
            guard: AddonGuard,
          },
          {
            exact: true,
            path: '/gerenciar/indicador-risco',
            component: lazy(() =>
              import('src/paginas/gestao-risco/indicador-risco')
            ),
            guid: ['91312b45-4425-421c-a93a-894f013a9a4d'],
          },
          {
            exact: true,
            path: '/gerenciar/indicador-risco/edit',
            component: lazy(() =>
              import('src/paginas/gestao-risco/indicador-risco/edit')
            ),
            guid: [
              '91312b45-4425-421c-a93a-894f013a9a4d',
              '0eb12a1e-449c-43e6-9a2c-de4bc7f9dbf8',
              'fc8194a0-53f5-482e-97cb-a4f7fc78a8d5',
              'ec81a6cb-0fe7-43d9-83ee-6a2066ffdf49',
              '60f40fdc-3227-44e2-b8ba-431a5336ab20',
            ],
          },
          {
            exact: true,
            path: '/gerenciar/processo',
            component: lazy(() => import('src/paginas/gestao-risco/processo')),
            guid: ['9535e6d5-e0f2-47f3-9472-2487643f69e5'],
          },
          {
            exact: true,
            path: '/gerenciar/processo/edit',
            component: lazy(() =>
              import('src/paginas/gestao-risco/processo/edit')
            ),
            guid: [
              '9535e6d5-e0f2-47f3-9472-2487643f69e5',
              '443091d2-dbce-42fc-9af7-686994b09942',
              '4d87277d-537c-4fbb-ae23-4d1ad58acd41',
              'cf3474e6-3b4e-4558-9b08-c1509e08e443',
              'c5a9e8a9-6185-4e50-8081-ec3ab255268a',
              '93992439-d732-44d3-90cd-ec8dad0845db',
              '89368e95-7c0d-49f3-8dfd-7e40273ecf3c',
            ],
          },
          {
            exact: true,
            path: '/gerenciar/ponto-controle',
            component: lazy(() =>
              import('src/paginas/gestao-risco/ponto-controle')
            ),
            guid: ['c67c50b9-982f-4217-a057-f30d07364727'],
          },
          {
            exact: true,
            path: '/gerenciar/revisoes-ponto-controle',
            component: lazy(() =>
              import(
                'src/paginas/gestao-risco/risco/RevisaoPontoControle/GerenciarRevisoesListView'
              )
            ),
            guid: ['3601a9b3-1d16-43bc-a917-2213bd59a918'],
          },
          {
            exact: true,
            path: '/gerenciar/revisoes-ponto-controle/edit',
            component: lazy(() =>
              import(
                'src/paginas/gestao-risco/risco/RevisaoPontoControle/RevisaoPontoControleEditView'
              )
            ),
            guid: ['3601a9b3-1d16-43bc-a917-2213bd59a918'],
          },
          {
            exact: true,
            path: '/gerenciar/okr',
            component: lazy(() => import('src/paginas/okr/Objetivo')),
            guid: ['687991f6-787f-4631-be4c-54eded439cdd'],
          },
          {
            exact: true,
            path: '/gerenciar/portfolio-projetos',
            component: lazy(() =>
              import('src/paginas/EscritorioProjetos/PortfolioProjetos')
            ),
          },
          {
            exact: true,
            path: '/gerenciar/meta-individual',
            component: lazy(() =>
              import('src/paginas/avaliacao-desempenho/MetaIndividual/Cesta')
            ),
            guid: ['3fe8efb2-caf7-47dd-aa59-79ac586b7b8b'],
            parameters: { addon: ADDONS.METAS_INDIVIDUAIS },
            guard: AddonGuard,
          },
          {
            exact: true,
            path: '/gerenciar/gestao-documentos',
            component: lazy(() =>
              import('src/paginas/GestaoDocumentos/Documento')
            ),
            guid: [''],
          },
          {
            exact: true,
            path: '/gerenciar/gestao-documentos/edit',
            component: lazy(() =>
              import('src/paginas/GestaoDocumentos/Documento/EditPage')
            ),
            guid: [''],
          },
          {
            component: () => <Redirect to="/404" />,
          },
        ],
      },

      {
        path: '/rv',
        routes: [
          {
            exact: true,
            path: '/rv/painel/cardmeta',
            component: lazy(() =>
              import('src/paginas/remuneracao-variavel/CardMeta')
            ),
          },
          {
            exact: true,
            path: '/rv/cadastrar/pontos-faixa-resultado',
            component: lazy(() =>
              import('src/paginas/remuneracao-variavel/pontos-faixa-resultado')
            ),
            guid: ['e50794ef-cf43-4af0-8de8-0e2bd4d911a7'],
          },
          {
            exact: true,
            path: '/rv/cadastrar/funcoes',
            component: lazy(() =>
              import('src/paginas/remuneracao-variavel/funcoes')
            ),
            guid: ['e78eafc0-0a02-485e-b321-5f186b267c82'],
          },
          {
            exact: true,
            path: '/rv/cadastrar/condicionante',
            component: lazy(() =>
              import('src/paginas/remuneracao-variavel/condicionante')
            ),
            guid: ['573c42e5-3717-4ded-b637-bf2d0e0f49d0'],
          },
          {
            path: '/rv/cadastrar/gatilho',
            component: lazy(() =>
              import('src/paginas/remuneracao-variavel/gatilho')
            ),
            guid: ['15957877-8c91-4a08-8f57-11e8d0ed8dff'],
          },
          {
            exact: true,
            path: '/rv/gerenciar/calculo-intermediario',
            component: lazy(() =>
              import('src/paginas/remuneracao-variavel/calculo-intermediario')
            ),
            guid: ['a34f83a5-99eb-4fe9-a0c9-462a0e795a8b'],
          },
          {
            exact: true,
            path: '/rv/gerenciar/calculo-intermediario/edit',
            component: lazy(() =>
              import(
                'src/paginas/remuneracao-variavel/calculo-intermediario/edit'
              )
            ),
            guid: [
              '56d36eb6-203e-412b-a5f1-62e636921015',
              'da3890bf-58d4-4a4a-bc5e-f9e30861df46',
              '74d8d964-9a36-4356-8e09-eadac1196351',
              'e9068c9b-52b9-4d1a-807e-b9d4546f2819',
              'e4c83823-8493-46cb-b243-7464f0e49ce4',
              'c0ea687f-f162-403d-8519-9fc013bf1bda',
            ],
          },
          {
            exact: true,
            path: '/rv/gerenciar/desempenho-individual',
            component: lazy(() =>
              import('src/paginas/remuneracao-variavel/desempenho-individual')
            ),
            guid: ['41221ae9-929c-4c9d-a446-48ea7ddc73b3'],
          },
          {
            exact: true,
            path: '/rv/gerenciar/desempenho-individual/edit',
            component: lazy(() =>
              import(
                'src/paginas/remuneracao-variavel/desempenho-individual/edit'
              )
            ),
            guid: [
              '2fe4fb60-cc10-44d9-a99c-dbc0dccac96a',
              'ddf63ec1-d6d7-413d-b0d1-8a1c415259e0',
              '168762a3-00c3-4511-813d-c17b9265e6a2',
              '3f551299-0214-4b1b-9e49-7f4e66289974',
              'e98d8af8-cd81-4401-9a10-a574acdea108',
              'bc74e885-45a6-4b0d-a5ef-e59f5216a4d3',
            ],
          },
          {
            exact: true,
            path: '/rv/acompanhar/bonus-colaborador',
            component: lazy(() =>
              import('src/paginas/remuneracao-variavel/bonus-colaborador')
            ),
            guid: ['f481f3de-f100-452e-b754-3205257ca409'],
          },
          {
            exact: true,
            path: '/rv/acompanhar/bonus-organizacao',
            component: lazy(() =>
              import('src/paginas/remuneracao-variavel/bonus-organizacao')
            ),
            guid: ['66876091-ce95-4b0d-a47e-5d9d30356f42'],
          },
          {
            exact: true,
            path: '/rv/painel/painel-desempenho',
            component: lazy(() =>
              import('src/paginas/remuneracao-variavel/painel-desempenho')
            ),
            guid: ['29d906e0-a3fa-4570-a187-b3d19eddf317'],
          },
          {
            exact: true,
            path: '/rv/acompanhar/score',
            component: lazy(() =>
              import('src/paginas/remuneracao-variavel/score')
            ),
            guid: ['45e545e6-dba8-4517-a78f-b35ac66102a3'],
          },
          {
            path: '/rv/acompanhar/simulador-comissao',
            component: lazy(() =>
              import('src/paginas/remuneracao-variavel/SimuladorComissao')
            ),
          },
          {
            path: '/rv/acompanhar/drilldown-comissoes',
            component: lazy(() =>
              import('src/paginas/remuneracao-variavel/DrilldownComissoes')
            ),
          },
          {
            path: '/rv/acompanhar/detalhamento-provisoes',
            component: lazy(() =>
              import('src/paginas/remuneracao-variavel/DetalhamentoProvisoes')
            ),
          },
          {
            component: () => <Redirect to="/404" />,
          },
        ],
      },

      {
        path: '/mo',
        routes: [
          {
            path: '/mo/acompanhar/matriz-resultado',
            component: lazy(() =>
              import('src/paginas/matriz-orcamentaria/matriz-resultado')
            ),
          },
          {
            path: '/mo/acompanhar/matriz-indicadores',
            component: lazy(() =>
              import('src/paginas/matriz-orcamentaria/matriz-indicadores')
            ),
          },
          {
            exact: true,
            path: '/mo/acompanhar/desvios-centro-custo',
            component: lazy(() =>
              import('src/paginas/matriz-orcamentaria/desvio-centro-custo')
            ),
          },
          {
            exact: true,
            path: '/mo/acompanhar/desvios-pacote-conta',
            component: lazy(() =>
              import('src/paginas/matriz-orcamentaria/desvio-pacote-conta')
            ),
          },
          {
            exact: true,
            path: '/mo/cadastrar/parametro-entidade',
            component: lazy(() =>
              import('src/paginas/matriz-orcamentaria/parametro-entidade')
            ),
            guid: ['0291d641-89c7-4364-912f-11f789dea75c'],
          },
          {
            exact: true,
            path: '/mo/cadastrar/parametro-entidade/configuracao-geral',
            component: lazy(() =>
              import(
                'src/paginas/matriz-orcamentaria/parametro-entidade/configuracao-geral'
              )
            ),
            guid: ['a6a3df0a-ea6b-4efd-96cd-b122feba9507'],
          },
          {
            exact: true,
            path: '/mo/cadastrar/centro-custo',
            component: lazy(() =>
              import('src/paginas/matriz-orcamentaria/centro-custo')
            ),
            guid: ['07b40677-f290-4597-9b80-b07b7f8e27f7'],
          },
          {
            exact: true,
            path: '/mo/cadastrar/rateio',
            component: lazy(() =>
              import('src/paginas/matriz-orcamentaria/rateio')
            ),
            guid: ['3c517f22-e329-4bd5-8671-74f29cdb802f'],
          },
          {
            exact: true,
            path: '/mo/cadastrar/grupo-envio',
            component: lazy(() =>
              import('src/paginas/matriz-orcamentaria/grupo-envio')
            ),
            guid: ['e6e40988-96da-47df-b341-8517ad7173ca'],
          },
          {
            exact: true,
            path: '/mo/acompanhar/boas-praticas',
            component: lazy(() =>
              import('src/paginas/matriz-orcamentaria/boas-praticas')
            ),
            guid: ['67b3bda1-b56a-4dd6-b22a-3641ef3790e8'],
          },
          {
            exact: true,
            path: '/mo/acompanhar/matriz-parametro',
            component: lazy(() =>
              import('src/paginas/matriz-orcamentaria/matriz-parametro')
            ),
            guid: ['dc2c742d-72ec-4b43-a701-fa7a5249c6ea'],
          },
          {
            exact: true,
            path: '/mo/acompanhar/grafico-indicadores',
            component: lazy(() =>
              import('src/paginas/matriz-orcamentaria/grafico-indicadores')
            ),
            guid: ['87cab9dd-ef3e-4da1-9157-cddae4f44b09'],
          },
          {
            exact: true,
            path: '/mo/configuracoes/geral',
            component: lazy(() => import('src/paginas/configuracao-geral')),
            guid: ['2b8017b4-a6cb-4bfe-970d-817b702e5127'],
          },
          {
            exact: true,
            path: '/mo/cadastrar/pacote',
            component: lazy(() =>
              import('src/paginas/matriz-orcamentaria/pacote')
            ),
            guid: ['4f608ab1-05e2-40c6-b08b-5b5e0e4a723a'],
          },
          {
            exact: true,
            path: '/mo/cadastrar/valores-pacote',
            component: lazy(() =>
              import('src/paginas/matriz-orcamentaria/valores-pacote')
            ),
            guid: [
              'bb85d88b-0e16-419c-ac51-20225cdc2537',
              '30b26c2a-7c64-4cf9-abcc-f0213e481b54',
              'ab3c1380-ed85-4a02-88f4-7b6ba363885b',
            ],
          },
          {
            exact: true,
            path: '/mo/cadastrar/valores-parametro',
            component: lazy(() =>
              import('src/paginas/matriz-orcamentaria/valores-parametro')
            ),
            guid: [
              '4e4f26d6-58a7-437b-86ba-4d17bc3f87f0',
              '4a34c0e3-a862-453f-a27b-814c457cdc0b',
            ],
          },
          {
            exact: true,
            path: '/mo/gerenciar/relatorio-acompanhamento',
            component: lazy(() =>
              import('src/paginas/matriz-orcamentaria/relatorio-acompanhamento')
            ),
          },
          {
            exact: true,
            path: '/mo/acompanhar/matriz-mensal',
            component: lazy(() =>
              import('src/paginas/matriz-orcamentaria/matriz-mensal')
            ),
            guid: ['9ef54f0b-1fb9-4b28-bf7f-d5f761d3b92f'],
          },
          {
            exact: true,
            path: '/mo/acompanhar/arvore-valores',
            component: lazy(() =>
              import('src/paginas/matriz-orcamentaria/arvore-valores')
            ),
            guid: ['480eb1f0-1c4f-40e1-ac3d-d477dd54faa6'],
          },
          {
            exact: true,
            path: '/mo/cadastrar/configuracoes-graficos/configuracao-geral',
            component: lazy(() =>
              import('src/paginas/matriz-orcamentaria/configuracoes-grafico')
            ),
            guid: ['71be2c96-a935-4ede-a4cc-7678c9b99604'],
          },
          {
            component: () => <Redirect to="/404" />,
          },
        ],
      },
      {
        // TODO - Auxiliar na fase de estabilização, remover futuramente
        path: '/lift/admin',
        routes: [
          {
            exact: true,
            path: '/lift/admin/ajustar-estrutura-notas-ciclos',
            component: lazy(() =>
              import(
                'src/paginas/avaliacao-desempenho/JobsTemporarios/AjustarEstruturaNotas'
              )
            ),
          },
          {
            exact: true,
            path: '/lift/admin/ajustar-feedback-mapa-interfaces',
            component: lazy(() =>
              import(
                'src/paginas/avaliacao-desempenho/JobsTemporarios/AjustarFeedbackMapaInterfaces'
              )
            ),
          },
          {
            path: '/lift/admin/recalcular-notas-ciclos',
            component: lazy(() =>
              import(
                'src/paginas/avaliacao-desempenho/JobsTemporarios/RecalcularNotasCiclos'
              )
            ),
          },
          {
            path: '/lift/admin/atualizar-lider-cargo',
            component: lazy(() =>
              import(
                'src/paginas/avaliacao-desempenho/JobsTemporarios/AtualizarLiderHistoricoCargos'
              )
            ),
          },
          {
            path: '/lift/admin/recalcular-conceito',
            component: lazy(() =>
              import(
                'src/paginas/avaliacao-desempenho/JobsTemporarios/RecalcularConceito'
              )
            ),
          },
          {
            path: '/lift/admin/ajustar-informacoes-fixas-avaliacoes-feedbacks',
            component: lazy(() =>
              import(
                'src/paginas/avaliacao-desempenho/JobsTemporarios/AtualizarInformacoesFixasAvaliacoesFeedbacks'
              )
            ),
          },
          {
            path: '/lift/admin/ajustar-informacoes-mapa-interface',
            component: lazy(() =>
              import(
                'src/paginas/avaliacao-desempenho/JobsTemporarios/AtualizarInformacoesMapaInterface'
              )
            ),
          },
          {
            path: '/lift/admin/ajustar-mapa-sucessao',
            component: lazy(() =>
              import(
                'src/paginas/avaliacao-desempenho/JobsTemporarios/AtualizarMapaSucessao'
              )
            ),
          },
          {
            path: '/lift/admin/atualizar-codimportacao-formacao-academica',
            component: lazy(() =>
              import(
                'src/paginas/avaliacao-desempenho/JobsTemporarios/AtualizarCodImportacaoFormacoesAcademicas'
              )
            ),
          },
          {
            component: () => <Redirect to="/404" />,
          },
        ],
      },
      {
        path: '/one-a-one',
        component: lazy(() =>
          import('src/paginas/avaliacao-desempenho/OneAOne')
        ),
      },
      {
        path: '/meu-pdi',
        exact: true,
        component: lazy(() => import('src/paginas/avaliacao-desempenho/PDI')),
      },
      {
        path: '/meu-pdi/biblioteca-acoes',
        exact: true,
        component: lazy(() =>
          import('src/paginas/avaliacao-desempenho/PDI/BibliotecaAcoes')
        ),
      },
      {
        path: '/job/:id',
        component: lazy(() => import('src/paginas/job')),
      },
      {
        path: '/temp/react-query',
        component: lazy(() => import('src/paginas/Seed/Valores')),
      },
      {
        path: '/feedback-continuo',
        component: lazy(() =>
          import('src/paginas/avaliacao-desempenho/FeedbackContinuo')
        ),
        parameters: { addon: ADDONS.FEEDBACK_CONTINUO },
        guard: AddonGuard,
      },
      {
        component: () => <Redirect to="/404" />,
      },
    ],
  },
];

export default routes;
