import React, { useEffect } from 'react';
import { Collapse, Navbar, Nav } from 'reactstrap';

import NavbarUser from './NavbarUser';
import NavbarModules from './NavbarModules';
import NavbarNotification from './NavbarNotification';
import NavbarMessage from './NavbarMessage';
import useSidebar from 'src/hooks/useSidebar';
import MdiIcon from 'src/componentes/mdi-icon';
import history from '../../history';
import useAuth from 'src/hooks/useAuth';
import ConfigApp from 'src/services/configApp';

function NavbarComponent() {
  const { changeStatus: changeSidebarStatus, collapsed } = useSidebar();
  const { imagemLogo } = useAuth();
  //const { }

  useEffect(() => {
    //dispatch(setNotifications());
    //dispatch(setMessages());
  }, []);

  let srcLogo = '/logo.png';

  if (imagemLogo && imagemLogo.guid != null)
    srcLogo = `${ConfigApp.ApiUrl}/ArquivoSistema/DownloadImagem?guid=${imagemLogo.guid}`;

  return (
    <Navbar
      fixed="top"
      color="white"
      light
      expand
      style={{
        height: 82,
        zIndex: 140,
        transition: 'padding-left 0.35s ease-in-out, left 0.35s ease-in-out',
        paddingLeft: !collapsed
          ? 'calc(260px + 1.25rem)'
          : 'calc(80px + 1.25rem)',
      }}
    >
      <MdiIcon
        onClick={changeSidebarStatus}
        size={25}
        icon="menu"
        className="main-menu"
      />
      <a onClick={() => history.push('/')}>
        <img
          className="logo"
          alt="logo"
          style={{ maxHeight: 23, maxWidth: 124 }}
          src={srcLogo}
        ></img>
      </a>
      <Collapse navbar>
        <Nav
          className="ml-auto"
          style={{ display: 'flex', alignItems: 'center' }}
          navbar
        >
          <NavbarModules />
          <NavbarMessage />
          <NavbarNotification />
          <NavbarUser />
        </Nav>
      </Collapse>
    </Navbar>
  );
}

export default NavbarComponent;
