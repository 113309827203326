import { useRef } from 'react';
import { useIntl } from 'react-intl';

import Dialog from 'src/componentes/dialog';
import Button from 'src/componentes/button';

function NewConfirm({
  primaryMessage,
  secondaryMessage,
  emphasysMessage,
  labelCancel,
  labelConfirm,
  onSaved,
  confirmColor = 'primary',
  cancelColor = 'secondary',
  handleCancel,
  showCancel = true,
  disableConfirm = false,
  ...rest
}) {
  const intl = useIntl();
  const ref = useRef();

  const handleClose = () => {
    if (ref.current) ref.current.close();

    handleCancel && handleCancel();
  };

  const handleSave = () => {
    handleClose();
    onSaved && onSaved();
  };

  const actions = [
    showCancel && (
      <Button key={1} onClick={handleClose} color={cancelColor} outline>
        {labelCancel
          ? labelCancel
          : intl.formatMessage({ id: 'label.cancelar' })}
      </Button>
    ),
    <Button
      key={2}
      color={confirmColor}
      className="ml-2"
      onClick={handleSave}
      disabled={disableConfirm}
    >
      {labelConfirm
        ? labelConfirm
        : intl.formatMessage({ id: 'label.confirmar' })}
    </Button>,
  ];

  return (
    <Dialog
      ref={ref}
      padContent
      onRequestClose={handleClose}
      width={580}
      maxWidth={580}
      minHeight={150}
      {...rest}
    >
      <div className="d-flex flex-column align-items-center justify-content-center px-5">
        <div className="mb-4 mt-2">
          <img alt="warning" src="/megaphone.svg" style={{ width: 100 }} />
        </div>
        <div className="font-weight-bolder mb-3 text-center text-xl">
          {primaryMessage}
        </div>
        <div className={`text-center ${!emphasysMessage && 'mb-2'}`}>
          {secondaryMessage}
        </div>
        {emphasysMessage && (
          <div className="text-center mb-2 font-weight-bolder">
            {emphasysMessage}
          </div>
        )}
        <div className="d-flex mt-4 mb-5">{actions}</div>
      </div>
    </Dialog>
  );
}

export default NewConfirm;
